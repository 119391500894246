import { Component, OnInit} from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { CountryCodeService } from 'src/app/views/service/country-code.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.template.html',
  styleUrls:['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {
 
  public loadBanner: boolean = true;
  constructor(
    public translate: TranslateService, 
    private cookieService:CookieService,
   // private toastservice: ToastsService,
   private countrycodeService: CountryCodeService,
   private router: Router
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
  };

    try {
      let lang=cookieService.get('lang');        
    }
    catch (err) {
      // this.toastservice.errorSnackBar({
      //     msg: ['Cookie get langeuage  '+err.messaage],
      //     type: 'error'
      // });
  
  }

    
    // Translator init
    //const browserLang: string = translate.getBrowserLang();
    this.loadBanner = this.inIframe();
    //translate.setDefaultLang(lang); 
    

  }
  inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
    }
    ngOnInit() {
      try {
        let countryCode: any = this.countrycodeService.getCountryCode()=='se' ? 'sv' : 'fi';
      this.translate.addLangs(['sv', 'en', 'fi']);
      // this.translate.setDefaultLang(countryCode);
        // this.translate.setDefaultLang('sv');
        if (this.translate.getBrowserLang() !== undefined) {
            let lang = this.cookieService.get('.AspNetCore.Culture');
            if (lang) {
                var langCode = lang.split("|")[0].split("=")[1].split("-")[0];
                this.translate.use(langCode);
            }else {
              this.translate.use(countryCode);
            }
        }
        else {
            // this.translate.use('sv');
            this.translate.use(countryCode);
        }        
      }
      catch (err) {
        // this.toastservice.errorSnackBar({
        //     msg: ['Add langeuage  '+err.messaage],
        //     type: 'error'
        // });
        
    }
  }
}