export class serviceResponse{
    ResultText:String
    ResultCode:number
}

export class registerPaymentResponse {
  failedInvoices:"";
  impPaymentId: number;
  journalId: number;
  result: serviceResponse;
}
