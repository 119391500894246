<div class="side-navbar" #mainScreen (scroll)="scrollHandler($event)">
  
  <!-- <div class="list side-nav" #menuScreen (window:resize)="onResize($event)" > -->
    <mat-nav-list class="mat-list-base" *ngIf="userDetails">
      <a mat-list-item class="sergal-grid-title" *ngIf="isAllowed('inbox')"  routerLink='/inbox' routerLinkActive="active">
        <div class="sidebar_container">
          <div class="sidebar_icon"><i class="icofont-mail-box sergal_icon_font"></i></div>
          <div class="sidebar_text">
            {{'inboxTab' | translate}}
          </div>
        </div>
      </a>
      <a mat-list-item class="sergal-grid-title" *ngIf="isAllowed('search')" routerLink='/search' routerLinkActive="active">
        <div class="sidebar_container">
          <div class="sidebar_icon"><i class="icofont-search-2 sergal_icon_font"></i></div>
          <div class="sidebar_text">
            {{'searchTab' | translate}}
          </div>
        </div>

      </a>
      <a mat-list-item class="sergal-grid-title" *ngIf="isAllowed('register-case')" routerLink='/register-case' routerLinkActive="active">
        <div class="sidebar_container">
          <div class="sidebar_icon"><i class="icofont-ui-edit sergal_icon_font"></i></div>
          <div class="sidebar_text">
            {{'registerCaseTab' | translate}}&nbsp;
          </div>
        </div>
      </a>

      <a mat-list-item class="sergal-grid-title" *ngIf="isAllowed('financial-report')" routerLink='/financial-report' routerLinkActive="active">
        <div class="sidebar_container">
          <div class="sidebar_icon"><i class="icofont-chart-bar-graph sergal_icon_font"></i></div>
          <div class="sidebar_text">
            {{'financialReportTab' | translate}}
          </div>
        </div>
      </a>
      <a mat-list-item class="end sergal-grid-title" routerLink='/help' routerLinkActive="active">
        <div class="sidebar_container" style="margin-top: 0px;">
          <div class="sidebar_icon"> <i class="icofont-life-buoy icon_help sergal_icon_font"></i></div>
          <div class="sidebar_text">
            {{'helpTab' | translate}}
          </div>
      </div>
    </a>
    </mat-nav-list>

  <!-- </div> -->


  <!-- <div class="footer" #ft>
    <div class="footer-container" #ftContainr>
      <div class="sergal_icon_font" style="margin:auto">
        <i class="icofont-life-buoy icon_help sergal_icon_font"></i>

      </div>

      <a matLine class="sergal_link icon_help_text" routerLink='/help' routerLinkActive="active" href="..."> Help</a>
    </div>

  </div> -->
</div>
