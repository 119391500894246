import { OnInit, Component, ViewChild, AfterViewInit, Inject, ElementRef } from "@angular/core";
import { MsgFileUploadComponent } from "./file-upload/msg-file-upload.component";
import { MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
// import {MAT_DIALOG_DATA} from '@angular/material/dialog';
// import { MatDialogRef } from "@angular/material/dialog/dialog-ref";
import { ToastsService } from "src/app/shared/services/toast-service";
import { Uploader } from "src/app/shared/models/uploader";
import { fileSizeEnum } from "src/app/shared/Enums/customEnums";
import { UploadQueue } from "src/app/shared/models/uploadqueque";
import { HttpRequest, HttpClient } from "@angular/common/http";
import { OverviewService, MessageTabUpdate } from "src/app/views/debtor/overview.service";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from 'src/app/views/inbox/message.service';
import { appConfig } from '../../services/config.service';
import { LoaderService } from '../loader/service/loader.service';



@Component({
  selector: 'reply-msg',
  styleUrls: ['reply-message.component.scss'],
  templateUrl: 'reply-message.component.html',
})
export class ReplyMessage implements AfterViewInit {
  disableTextArea: boolean = false;
  remainingText: number = 1500;
  isUploaded: boolean = false;
  showAttachment = true;
  replyBox = "";


  uploadBtn: boolean = false;
  uploadQuelength: number = 0;
  panelOpenState = false;
  uploadDataVal: any = {
    "caseLookupArray": {
      "caseLookup": [{
        "CaseIdentification": 1,
        "CaseIdentificationValue": ""
      }]
    },
    "documentLookupModel": {
      "DocumentType": "",
      "FileDataBase64": "",
        "OriginalFileName": "",
      "file":"",
    }
  }
    accepts: any = ['pdf', 'jpg', 'JPG', 'PDF', 'jpeg', 'JPEG', 'png', 'PNG', 'XLSX', 'xlsx', 'XLS', 'xls', 'DOCX', 'docx', 'DOC', 'doc', 'MSG', 'msg'];
  public progressText: string;
  public message: string;
  public uploader: Uploader = new Uploader();
  apiUrl: any = '';
  fileNameQueue = [];
  fileNameQueueLength: number = 0;
  mode: string = 'indeterminate';
  @ViewChild('myInput') myInputVariable: ElementRef;
  public progress: any = {
    loaded: 0,
    total: 0
  };
  uploadFileSize = 0;
  replyModalType ="";
  successfullySubmitted = false;
  constructor(private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public msgData: any,
    public urlConfigs: appConfig,
    public dialogRef: MatDialogRef<MsgFileUploadComponent>,
    private toastservice: ToastsService,
    public overviewService: OverviewService,
    public messageTabUpdate: MessageTabUpdate,
    private messageService: MessageService,
    private translate: TranslateService,
    private loaderService: LoaderService
  ) {
    this.message = '';
    if(this.msgData.event == "NewConversation"){
      this.replyModalType = this.translate.instant("writeMessageModalHeading");
    }else if(this.msgData.event.currentTarget && this.msgData.event.currentTarget.value == "Reply"){
      this.showAttachment = true;
    }
    else if (this.msgData.event.currentTarget && this.msgData.event.currentTarget.value == "Bifoga dokument") {
      this.showAttachment = true;
    }else if (this.msgData.event.value == "Reply" || this.msgData.event.value == "Bifoga dokument") {
      this.showAttachment = true;
    }
    else{
      this.replyModalType = this.translate.instant("replyModalHeading");
    }
    
  }

  ngAfterViewInit() {
    this.progressText = this.translate.instant("replyModalUploadInProgress");  
    if (this.showAttachment) {
      // this.isUploaded = this.MsgFileUploadComponent.uploadBtn;
      }
      this.toastservice.closeToast();
  }

  //Limit text area and validate
  textLimit(event) {
    let textLen = event.srcElement.value.length;
    this.remainingText = 1500 - this.replyBox.length;
    if (this.remainingText < 0) {
      return false;
    }
    if (textLen >= 1500) {
      if (event.keyCode == 8) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  }

  //convert base 64 using getBase method
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  //drop the file
  onFilesChange(fileList: Array<File>) {
    if (this.uploadQuelength) {
      this.errorSuccess({
        msg: [this.translate.instant("replyModalOneAttachmentErr")],
        type: 'error'
      });
      return false;
    }
    for (let file of fileList) {
      let fileSize = Math.ceil(file.size / 1024 / 1024);
      if (fileSize <= 5) {

        let itemIndex = this.uploader.queue.findIndex(x => x.file.name === file.name);
        if (itemIndex === -1) {
          this.uploader.queue.push(new UploadQueue(file));
          if (this.uploader.queue.length) {
            this.uploadQuelength = this.uploader.queue.length;
            this.isUploaded = true;
            this.uploadData();
          }
        }

      } else {
        this.errorSuccess({
          msg: [this.translate.instant("replyModalFileSizeErr")],
          type: 'error'
        });
      }
    };
  }

  //file invalid check
  onFileInvalids(fileList: Array<File>) {

    for (let file of fileList) {
        // fileSize = fileSize + value.file.size / 1024 / 1024;
        let type = file.name.split('.')[file.name.split('.').length - 1].toLowerCase();//file.name.split('.')[1].toLowerCase();
        if (type === "pdf" || type == "jpeg" || type == "jpg" || type == "png" || type == "xlsx" || type == "xls" || type == "docx" || type == "doc" || type == "msg") {

      } else {
        this.errorSuccess({
          msg: [this.translate.instant("replyModalFileTypeErr")],
          type: 'error'
        });
      }

    };

    //TODO handle invalid files here
  }

  //select the multiple file method
  onSelectChange(event: EventTarget) {
    // if(!this.isUploaded){
    const fileUpload = document.getElementById('file') as HTMLInputElement;
    fileUpload.onchange = (e) => {
      //let fileSize = Math.ceil(fileUpload.files[0].size / 1024 / 1024);
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        let fileSize = Math.ceil(fileUpload.files[index].size / 1024 / 1024); // This calculates in MB

        if (this.uploadQuelength) {
          this.errorSuccess({
            msg: [this.translate.instant("replyModalOneAttachmentErr")],
            type: 'error'
          });
          return false;
        }
          if (fileSize <= fileSizeEnum.CaseUploadFileSizeInMB) {
              let type = fileUpload.files[index].name.split('.')[fileUpload.files[index].name.split('.').length - 1].toLowerCase();
          if (type === "pdf" || type == "jpeg" || type == "jpg" || type == "png" || type == "xlsx" || type == "xls" || type == "docx" || type == "doc" || type == "msg") {
            let itemIndex = this.uploader.queue.findIndex(x => x.file.name === file.name);
            if (itemIndex === -1) {
              this.uploader.queue.push(new UploadQueue(file));
              if (this.uploader.queue.length) {
                this.uploadQuelength = this.uploader.queue.length;
                this.isUploaded = true;
                this.uploadData();
              }
            }
          } else {
            this.errorSuccess({
              msg: [this.translate.instant("replyModalFileTypeErr")],
              type: 'error'
            });
          }
        } else {
          // make a tost message
          this.errorSuccess({
            msg: [this.translate.instant("replyModalFileSizeErr")], 
            type: 'error'
          });
        }

      }
    };
    fileUpload.click();

  }

  // upload fill method using api
  upload(id, uploadDataVal) {

    if (id == null)
      return;

    let selectedFile = this.uploader.queue.find(s => s.id == id);
    if (selectedFile) {
      const formData = new FormData();
      
      formData.append(selectedFile.file.name, selectedFile.file);
      this.mode = 'indeterminate';      
    }
  }

  //update file table
  // loadFile() {
  //   this.msgUploadService.getFileDetails(this.msgData.caseGuid).subscribe((fileData: any) => {
  //     this.msgUploadService.setFile(fileData.data.fileList);
  //   })
  // }

  //cancel file method
  cancelFile(file: UploadQueue) {

    if (file) {
      if (file.sub) {
        file.sub.unsubscribe();
      }
      this.myInputVariable.nativeElement.value = "";
      this.removeFileFromArray(file);
      if (this.remainingText == 1500) {
        this.isUploaded = false;
      }
    }
  }
  private removeFileFromArray(file: UploadQueue) {
    const index = this.uploader.queue.indexOf(file);
    if (index > -1) {
      this.uploader.queue.splice(index, 1);
      this.uploadDataVal = {
        "caseLookupArray": {
          "caseLookup": [{
            "CaseIdentification": 1,
            "CaseIdentificationValue": ""
          }]
        },
        "documentLookupModel": {
          "DocumentType": "",
          "FileDataBase64": "",
          "OriginalFileName": "",
          "file": "",
        }
      };
      if (this.uploader.queue.length == 0) {
        this.uploadQuelength = 0;
      }
    }
  }

  //upload all selected files to server
  uploadData() {
    //find the remaning files to upload
    // this.uploadBtn = true;
    let remainingFiles = this.uploader.queue.filter(s => !s.isSuccess);
    let failedFileUpload: string = "";
      this.fileNameQueue = [];
      
    for (let item of remainingFiles) {

      this.getBase64(item.file).then(
        (data: string) => {
          this.uploadDataVal.documentLookupModel.FileDataBase64 = data; //btoa(data);
              this.uploadDataVal.documentLookupModel.OriginalFileName = item.file.name;
              this.uploadDataVal.documentLookupModel.file = item.file;
          this.uploadDataVal.documentLookupModel.DocumentType = item.file.name.split('.').pop();
          this.upload(item.id, this.uploadDataVal);
          if (item.file.name === (this.uploader.queue[this.uploader.queue.length - 1]).file.name) {
            //this.errorSuccess({ msg: 'File upload successfuly', type: 'success' });
          } else {
            failedFileUpload = failedFileUpload + "\n" + item.file.name;
          }
        })

      if (failedFileUpload != "") {
        this.errorSuccess({
          msg: [this.translate.instant("replyModalFileUploadFail")+ failedFileUpload],
          type: 'error'
        });
      }

    }
  }

  //For updating message Tab
  messageTabData(caseNumber) {
    //let Guid = this.caseGuid;
    this.messageService.messageCaseData(caseNumber).subscribe(response => {
      if(response.result.resultCode == 0){
        try {
          this.messageTabUpdate.setMessages(response.data.messages);
        } catch (err) {
          this.toastservice.errorSnackBar({
            msg: ['Message case data' + err.messaage],
            type: 'error'
          });
        }
      }else{
        this.toastservice.errorSnackBar({ msg: [response.result.resultText], type: 'error' }); 
      }
    },(error)=>{
      this.toastservice.errorSnackBar({
                  msg: ['Status : '+error.status + '  Response :' + error.statusText],
                  type: 'error'
              });
    })
  }

  

  sendData() {
    if (this.replyBox.trim() != "") {
      if (this.uploader.queue.length != 0) {
        this.uploadBtn = true;
      }
      this.msgData.replyModalDetail.RespondedText = this.replyBox.trim();
      console.log(this.msgData.replyModalDetail);
      this.loaderService.startLoading();
      this.messageService.sendMessageResponses(this.msgData.replyModalDetail, this.uploadDataVal, this.msgData.event).subscribe((msgResponse) => {
        this.loaderService.stopLoading();
        this.mode = 'determinate';
        if (msgResponse.result.resultCode == 0) {
          try {
            if (this.msgData.componentName == "caseOverview") {
                this.successfullySubmitted = true;
                this.dialogRef.close(this.successfullySubmitted);
              // this.messageTabData(this.msgData.replyModalDetail.CaseIdentificationValue);
            }
            else {
              //   this.messageService.GetInboxMessagesForClient().subscribe((tableData) => {
              //       if (tableData.result.resultCode == 0) {
              //         try {
              //           this.messageService.setInboxTable(tableData);
              //         } catch (err) {
              //           this.toastservice.errorSnackBar({
              //             msg: ['Inbox message for client' + err.messaage],
              //             type: 'error'
              //           });
              //         }
              //       }
              //       else if (tableData.result.resultCode == 88) {
              //           this.toastservice.errorSnackBar({ msg: [tableData.result.resultText], type: 'error' });
              //       }
              //       else{
              //         this.toastservice.errorSnackBar({ msg: [tableData.result.resultText], type: 'error' });
              //       }
              // },(error)=>{
              //   this.toastservice.errorSnackBar({
              //               msg: ['Status : '+error.status + '  Response :' + error.statusText],
              //               type: 'error'
              //           });
              // })
            }
            // this.dialogRef.close();
            this.toastservice.errorSnackBar({ msg: [this.translate.instant("replyModalSuccess")], type: 'success' });
          } catch (err) {
            this.loaderService.stopLoading();
            this.toastservice.errorSnackBar({
              msg: ['Send message response ' + err.messaage],
              type: 'error'
            });
          }
        } else {
          this.toastservice.errorSnackBar({ msg: [msgResponse.result.resultText], type: 'error' });
        }
      },(error:any)=>{
        this.toastservice.errorSnackBar({
                    msg: ['Status : '+error.status + '  Response :' + error.statusText],
                    type: 'error'
                });
      });
    }
    else {
      this.errorSuccess({
        msg: [this.translate.instant("replyModalMsgBlackErr")],
        type: 'error'
      });
      
    }
    
  }

  //error message service call
  errorSuccess(message: any) {
    this.toastservice.errorSnackBar(message);
  }
}
