import { Directive, HostListener, HostBinding, EventEmitter, Output, Input } from '@angular/core';

@Directive({
  selector: '[appDnd]'
})  
export class DndDirective {
  @Input() public allowed_extensions: Array<string> = [];
  @Output() private filesChangeEmiter: EventEmitter<File[]> = new EventEmitter();
  @Output() private filesInvalidEmiter: EventEmitter<File[]> = new EventEmitter();
  @HostBinding('style.background') private background = '#fff';
  @HostBinding('class') elementClass='area dragleave';
  constructor() { }

  @HostListener('dragover', ['$event']) public onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = 'rgba(7, 90, 180, 0.05)';
    this.elementClass='area dragover';
    var data = evt.dataTransfer.getData("text");
    console.log(data);
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#fff';
    this.elementClass='area dragleave';
  }

  @HostListener('drop', ['$event']) public onDrop(evt) {
    console.log("alert");
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#fff';
    this.elementClass='area dragleave';
    let files = evt.dataTransfer.files;
    let valid_files: Array<File> = [];
    let invalid_files: Array<File> = [];
    if (files.length > 0) {
        for (let file of files) {
        let ext = file.name.split('.')[file.name.split('.').length - 1];
        if (this.allowed_extensions.lastIndexOf(ext) != -1) {
          valid_files.push(file);
        } else {
          invalid_files.push(file);
        }
      };
      this.filesChangeEmiter.emit(valid_files);
      this.filesInvalidEmiter.emit(invalid_files);
    }
  }

}
