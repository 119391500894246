import { Injectable } from "@angular/core";
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/Rx';

import { map, catchError } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { tap } from "rxjs/operators";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { appConfig } from "src/app/shared/services/config.service";

import { ErrorHandlerService } from "src/app/shared/services/global-error.service";
import { RegisterPayment } from "src/app/shared/models/registerPayment.model";
import { serviceResponse, registerPaymentResponse } from "src/app/shared/models/serviceResponse.model";
import { CustomHttpClient } from "src/app/shared/services/http-client.service";

@Injectable({
  providedIn: 'root'
})

export class OverviewService {
  private withdrawData = false;
  public API_URL: string;
  private subject = new Subject<any>();
  public overviewUpdateSubject = new Subject<any>();
  private ssnSunject = new Subject<string>();
  private ssn = '';
  constructor(private errorHandlerService: ErrorHandlerService,
    private http: CustomHttpClient, public urlConfigs: appConfig,
    private httpclient: HttpClient) {
    this.API_URL = this.urlConfigs.baseAppUrl + "CaseOverView/";
  }

  getFilesforCase(caseGuid) {
    return this.http
    .noLoaderHttp(this.API_URL + 'GetCaseFiles/' + caseGuid)
      .map((res) => {
        const filesData = res; //.json()
        return filesData;
      }).catch(this.handleError)
  }
  
  getCaseDetails(CaseGuid) {
    return this.http
    .get(this.API_URL + 'GetCaseOverView/' + CaseGuid)
    .map((res: Response) => {
      const caseDetails = res;
      return caseDetails;
    })
  }

  //For getting Message tab Data 
  messageCaseData(Guid): Observable<any> {
    return this.http
      .noLoaderHttp(this.API_URL + 'GetMessagesForCase/' + Guid)
      .map(response => {
        const msgTabData = response; //
        return msgTabData;
      })
  }

  // For Withdraw Case
  public revokeCase(Guid): Observable<any> {

    return this.http
        .get(this.API_URL + 'GetRevokeCase/' + Guid)
        .map(response => {
            const WithdrawResponse = response;
            return WithdrawResponse;
        })
        
}

  //Get All search data
  public PostRegisterPayment(registerData: RegisterPayment): Observable<registerPaymentResponse> {
    return this.http
      .post(this.API_URL + 'PostDirectPayment/', registerData)
      .map((response: any) => {
        //let data = response//; //
        //let serviceResponseObj = new registerPaymentResponse();
        //serviceResponseObj.impPaymentId = data.data.impPaymentId;
        //serviceResponseObj.journalId = data.data.journalId;
        //serviceResponseObj.result = new serviceResponse();
        //serviceResponseObj.result.ResultCode = data.result.resultCode;
        //serviceResponseObj.result.ResultText = data.result.resultText;

        // return serviceResponseObj;
        return response;
      })
  }
  setFile(dataset: any) {
    console.log(dataset);
    this.subject.next(dataset);
  }
  getFile() {
    return this.subject.asObservable()
  }

  setOverviewUpdate(overviewUpdateData) {
    this.withdrawData = overviewUpdateData;
    this.overviewUpdateSubject.next(this.withdrawData)
  }
  getOverviewUpdate() {
    return this.overviewUpdateSubject.asObservable();
  }
  //Handle error of api
  private handleError(error: Response | any) {
    console.error(error);
    return Observable.throw(error);
  }


  filedetails() {
    return [
      { 'uploadedDate': '2019-10-16', 'fileName': 'file1.pdf', 'format': 'PDF', 'uploaded': 'Sergel' },
      { 'uploadedDate': '2019-10-15', 'fileName': 'file2.pdf', 'format': 'PDF', 'uploaded': 'Annika G' }
    ]
  }
}

@Injectable()
export class MessageTabUpdate {
  private MsgData = new Subject<any>();

  getMessages() {
    return this.MsgData.asObservable();
  }

  clearMessages() {
    this.MsgData.next();
  }

  setMessages(data: any) {
    this.MsgData.next(data);
  }

}





