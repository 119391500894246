<div class="wrapper reply-msg">
    <div class="modal_header">
      <button class="close-upload" mat-dialog-close aria-hidden="true">
        <!-- <i class="icofont-close-line"></i> -->
        <i class="icofont-close-line " mat-dialog-close aria-hidden="true"></i>
      </button>
    </div>
  
    <mat-dialog-content [ngClass]="{'modal-content': showAttachment}">
        <div class="row">
            <div class="col-12">
                <h5 class="reply-header">
                  <span *ngIf="this.replyModalType == 'Reply to message'"> {{"replyModalHeading" | translate}}</span>
                  <span *ngIf="this.replyModalType == 'Write a message' || this.replyModalType == 'Skriv ett meddelande' || this.replyModalType == 'Kirjoita viesti'"> {{"writeMessageModalHeading" | translate}}</span> 
                </h5>
                <p class="msg-text">
                  {{msgData.replyModalDetail.MessageText}}
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h6 *ngIf="this.replyModalType != 'Write a message'" class="reply"> {{"replyModalReply" | translate}} </h6>
                <textarea maxlength = "1500"  class="text-area"   rows="6"  [(ngModel)]="replyBox" (keyup)="textLimit($event)"></textarea>
                  <div class="Characters-remaining"> {{"replyModalCharRemaining" | translate}} : {{remainingText}}</div>
            </div>
        </div>
        <mat-expansion-panel *ngIf="showAttachment">
          <mat-expansion-panel-header>
            <mat-panel-description class="accordion-title">
              {{"replyModalClickHere" | translate}}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col-12">
              <mat-dialog-content class="mat-typography">
                <div class="area" appDnd (filesChangeEmiter)="onFilesChange($event)" (filesInvalidEmiter)="onFileInvalids($event)" [allowed_extensions]="accepts">
                  <div class="dropzone">
                    <div class="progress" *ngIf="uploadBtn==true;else uploadBtns ">
                      <div class="progtext">{{'replyModalUploadProgress' | translate}}</div>
                      <mat-progress-bar class="progress_bar" mode="{{mode}}" [value]="progress.loaded"></mat-progress-bar>
                    </div>
                    <ng-template #uploadBtns>
                      <div class="file_upload">
                        <img class="drag-img" src="assets/images/file_upload.svg" />
                        <img class="drop-img" src="assets/images/file_upload_gray.svg" />
                      </div>
                      <h1 class="drop-file"> {{"replyModalDropFiles" | translate}} </h1>
                      <h1 class="drag-file"> {{"replyModalDragFiles" | translate}} <span class="browse" (click)="onSelectChange($event)">{{"fileBrowse" | translate}}</span></h1> <!--[ngClass]="{'browse-diasble':isUploaded == true}"-->
                      <div class="pdfpng-5"> {{"replyModalPdfORJpg" | translate}} </div>
                    </ng-template>
                  </div>
                </div>
                <!-- </div> -->
                <div>
                  <!--  -->
                  <mat-list *ngIf="uploadBtn==false">
                    <mat-list-item *ngFor="let item of uploader.queue; let last = last">
                      <p mat-line>{{item.file.name}}</p>
                      <mat-icon (click)="cancelFile(item)" style="cursor: pointer;"><i class="icofont-bin"></i></mat-icon>
                      <mat-divider *ngIf="!last"></mat-divider>
                    </mat-list-item>
                  </mat-list>
                  <mat-list *ngIf="fileNameQueueLength>0 && uploadBtn==true">
                    <mat-list-item *ngFor="let item of fileNameQueue; let last = last">
                      <p mat-line>{{item.fileName}}</p>
                      <mat-icon><i class="icofont-checked" *ngIf="item.type=='suceess'"></i><i class="icofont-close-squared" *ngIf="item.type=='error'"></i></mat-icon>
                      <mat-divider *ngIf="!last"></mat-divider>
                    </mat-list-item>
                  </mat-list>
                </div>
                <input type="file" id="file" uploader="uploader" #myInput name="fileUpload" multiple="multiple" style="display:none;" />
              </mat-dialog-content>
            </div>
          </div>
        </mat-expansion-panel>
        
    </mat-dialog-content>
    <!-- <div class="modal_footer"> -->
    <mat-dialog-actions align="end" class="mat-footer">
      <div class="sergel-two">
        <div class="outline-btn_border">
          <button class="succes_btn btn-dark" [ngClass]="{'btn-disabled': remainingText == 1500 }" (click)="sendData()" mat-button cdkFocusInitial>{{"replyModalSend" | translate}}</button> <!-- [ngClass]="{'btn-disabled':isUploaded == false && remainingText == 300 }"-->
        </div>
      </div>
    </mat-dialog-actions>
  </div>
  
