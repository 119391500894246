import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthGuard } from './shared/services/auth/auth.guard';

export const rootRouterConfig: Routes = [
  { 
    path: '', 
    redirectTo: 'home', 
    pathMatch: 'full' 
  },

  {
    path: '', 
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
      children: [
          {
              path: 'home',
              loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule),
              data: { title: 'Home', breadcrumb:"",auth:'home'}
          },
      {
        path: 'inbox',
        loadChildren: () => import('./views/inbox/inbox.module').then(m => m.InboxModule),
        data: { title: 'Inbox', breadcrumb: 'Inbox',auth:'inbox' }
      },
      {
        path: 'search',
        loadChildren: () => import('./views/search/search.module').then(m => m.SearchModule),
        data: { title: 'Search', breadcrumb: 'Search',auth:'search' }
      },
      {
        path: 'debtor',
        loadChildren: () => import('./views/debtor/debtor.module').then(m => m.DebtorModule),
        data: { title: 'Debtor', breadcrumb: 'Debtor',auth:'debtor'}
      },
      {
        path:'register-case',
        loadChildren:() => import('./views/register-case/register-case.module').then(m => m.RegisterCaseModule),
        data:{title:'Register-case',breadcrumb:'Resister Case',auth:'register-case'}
      },
      {
        path:'financial-report',
        loadChildren:() => import('./views/financial-reports/financialreport.module').then(m => m.FinancialReportModule),
        data:{title:'financial-report',breadcrumb:'financial-report',auth:'financial-report'}
      },
      {
        path: 'help',
        loadChildren: () => import('./views/help/help.module').then(m => m.HelpModule),
        data: { title: 'Help', breadcrumb: 'Help',auth:'help' }
      },
    ]
  },
  { 
    path: '**', 
    redirectTo: 'home',
  }
];
