import { Injectable } from "@angular/core";
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/Rx';

import { map, catchError } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { tap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";

import { appConfig } from "src/app/shared/services/config.service";
import { ErrorHandlerService } from "src/app/shared/services/global-error.service";
import { RegisterPayment } from "src/app/shared/models/registerPayment.model";
import { serviceResponse, registerPaymentResponse } from "src/app/shared/models/serviceResponse.model";

// const API_URL = "https://localhost:44369/api/InkassoSession/GetCaseDetailsAsync"


@Injectable({
    providedIn:'root'
})

export class MsgUploadService {
  public API_URL: string;
  private subject=new Subject<any>();
  private ssnSunject=new Subject<string>();
  private ssn='';
  constructor(private errorHandlerService:ErrorHandlerService,
     private http: HttpClient,public urlConfigs: appConfig,) { 
    this.API_URL = this.urlConfigs.baseAppUrl + "InkassoSession/";
  }
  //get file list of using getFileDetails method
  getFileDetails(caseguid:any)
  {
    //.pipe(map((response: any) => response.json()));
    return this.http.get(this.API_URL + 'FilesDetails/0/' + caseguid).map((res: Response) => {
      return res;
    }).catch(this.handleError); 
  }
  getCaseDetails(CaseIdentification, CaseIdentificationValue)
  {
   return this.http.get(this.API_URL+'CaseDetails/'+CaseIdentification+"/"+CaseIdentificationValue).map((res:Response)=>{
      return res;
    }).catch(this.handleError);
  }

  public getGetCaseDetailsData(CaseIdentification, CaseIdentificationValue) {
    return this.http.get(this.API_URL+'GetCaseDetailsAsync' + "?CaseIdentification=" + CaseIdentification + "&CaseIdentificationValue=" + CaseIdentificationValue)
            .pipe(catchError(this.errorHandlerService.handleError));
    
  }
  //Get All search data
  public PostRegisterPayment(registerData: RegisterPayment): Observable<registerPaymentResponse> {
    return this.http
      .post(this.API_URL+'PostRegisterPayment/', registerData)
      .map((response:any) => {
        let data = response//.json();
        //let  serviceResponseObj = new serviceResponse();
        //serviceResponseObj.ResultCode = data.result.resultCode;
        //serviceResponseObj.ResultText = data.result.resultText;

        let serviceResponseObj = new registerPaymentResponse();
        serviceResponseObj.impPaymentId = data.data.impPaymentId;
        serviceResponseObj.journalId = data.data.journalId;
        serviceResponseObj.result = new serviceResponse();
        serviceResponseObj.result.ResultCode = data.result.resultCode;
        serviceResponseObj.result.ResultText = data.result.resultText;

          return serviceResponseObj;
      })
      .catch(this.handleError);
  }
  setFile(dataset:any)
  {
    console.log(dataset);
    this.subject.next(dataset);
  }
  getFile()
  {
    return this.subject.asObservable()
  }
  
  //Handle error of api
  private handleError(error: Response | any) {
    console.error(error);
    return Observable.throw(error);
  }
    // filedetails(){
    //   return[
    //     {'uploadedDate':'2019-10-16','fileName':'file1.pdf','format':'PDF','uploaded':'Sergel'},
    //     {'uploadedDate':'2019-10-15','fileName':'file2.pdf','format':'PDF','uploaded':'Annika G'}
    //   ]
    // }
}





