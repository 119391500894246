<div class="mdl-layout mdl-js-layout mdl-layout--fixed-header sergel_header">
  <header class="custom-header mdl-layout__header mdl-layout__header--waterfall">
    <!--Start App Logo -->
    <div class="topbar-branding">
      <div class="logo">
        <!-- <img src="assets/images/Sergel_Logo.svg" alt="Sergel_logo" class="app-logo"> -->
        <img src="assets/images/SergelLogo.svg" alt="Sergel_logo" class="app-logo">
      </div>
    </div>
    <!-- End App Logo -->
    <div class="mdl-layout__header-row">


      <div class="mdl-layout-spacer"></div>
      <nav class="mdl-navigation mdl-layout--large-screen-only">
      </nav>
    </div>
    <!-- Menu -->


    <div class="mdl-side-nav-right" [ngClass]="{'active':show==true}">

      <mat-list class="sergel_mat_list_base">

        <mat-list-item class="sergal_list_item"> <span class="text_style">Inkasso</span>&nbsp;<i class="material-icons inkaso_icon md-24">apps</i></mat-list-item>


        <mat-list-item class="sergal_list_item">
          <mat-menu #menuLanguage="matMenu">
            <button mat-menu-item *ngFor="let lang of availableLangs" (click)="setLang(lang)">{{lang.name}}</button>

          </mat-menu>
          <button class="mat-menu_button" mat-button [matMenuTriggerFor]="menuLanguage">
            <span class="text_style align_menu" style="text-transform:uppercase;">{{currentLang}}</span>&nbsp;
            <i class="icofont-globe icon_menu"></i> 
          </button>

        </mat-list-item>
        <mat-list-item class="sergal_list_item">
           <span class="sergel_align text_style" *ngIf="userDetails && userDetails.fullName">{{userDetails.fullName}}</span>  
           <i class="icofont-sign-out sergel_icon_menu"></i>
        </mat-list-item>
        <mat-list-item class=" sergal_list_item icon" (click)="navbarMenu()"><i class="icofont-navigation-menu"></i></mat-list-item>
      </mat-list>
    </div>

  </header>
</div>
