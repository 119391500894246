import { NgModule } from '@angular/core';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { rootRouterConfig } from './app.routing';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';

import { HttpClient, HttpClientModule} from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SearchService } from './views/service/search.service';

import { ToastsComponent } from './shared/components/toasts/toasts.component';

//import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, SatDatepickerModule } from 'saturn-datepicker'
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { ValidationService } from './shared/services/validation-service';
import { MY_FORMATS } from './shared/models/dateFormats';
import { CookieService } from 'ngx-cookie-service';
import { AuthorizationService } from './shared/services/auth/authorization.service';
import { AuthGuard } from './shared/services/auth/auth.guard';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { MatPaginatorI18nService } from './shared/services/MyMatPaginatorIntl';
import { APP_BASE_HREF } from '@angular/common';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CountryCodeService } from './views/service/country-code.service';


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
 
    //SatDatepickerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: true

    }),
    RouterModule.forRoot(rootRouterConfig, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload' })

  ],
  declarations: [AppComponent, LoaderComponent],

  providers: [
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig },
    AuthorizationService, AuthGuard, SearchService, ValidationService,

    //{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    //{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    // { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorI18nService,
    },
    { provide: APP_BASE_HREF, useFactory: getBaseUrl },
    CookieService,
    CountryCodeService
  ],
  entryComponents: [ToastsComponent],
  bootstrap: [AppComponent]
})
export class AppModule {
  obj: string;
}

export function HttpLoaderFactory(http: HttpClient) {
  // return new TranslateHttpLoader(http, './assets/i18n/', '.json');
  return new TranslateHttpLoader(http, "./assets/i18n/"+getBaseUrl(),".json");

}
export function getBaseUrl() {
  window['base-href'] = window.location.pathname;
  var country = window['base-href'].split('/')[1];
  return "/" + country + "/"; //document.getElementsByTagName('base')[0].href;
}

// export function getBaseUrl() {
//   return document.getElementsByTagName('base')[0].href.split('/')[3];
// }
// export function HttpLoaderFactory(httpClient: HttpClient) {
//   return new TranslateHttpLoader(httpClient, "./assets/i18n/"+getBaseUrl()+"/overview/", ".json");
// }
