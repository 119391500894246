<div class="app-admin-wrap">

  <mat-sidenav-container>
    <app-top-banner *ngIf="!loadBanner"></app-top-banner>
    <div class="sidenav" [ngClass]="{'iframeSideNav':loadBanner}">
      <app-sidenav></app-sidenav>
    </div>
    <div class="main" [ngClass]="{'iframeMain':loadBanner}">


      <div class="grid" style="background-color:white!important;">
        <div class="col-12 white">
          <!-- <app-breadcrumb></app-breadcrumb> -->
          <router-outlet></router-outlet>
        </div>
      </div>

    </div>
  </mat-sidenav-container>

</div>
<app-toasts></app-toasts>


