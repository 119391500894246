/*********************************************************************
Name: urlConfigs
=================================================================
Purpose:Declare the url

==================================================================
History
--------
Version   Author                     Date              Detail
1.0       Sudhansu                21/05/2019       Initial Version

****************************************************************/ 

import { Injectable, Inject } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';

@Injectable({
    providedIn:'root'
})
export class appConfig {
  readonly baseAppUrl: string = '/api/';
  countryCode: string;
  constructor(@Inject(APP_BASE_HREF) private baseHref: string) {
   
    this.countryCode = this.baseHref.split("/")[1];
    console.log(this.countryCode);
    this.baseAppUrl = "/" + this.countryCode + "/api/"
  }
}
