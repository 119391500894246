import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ToastsComponent } from '../components/toasts/toasts.component';
import { Toasts } from '../models/toasts';
import {MatSnackBar} from '@angular/material/snack-bar';


@Injectable({
    providedIn: 'root'
  })
  
export class ToastsService {
    durationInSeconds = 5;
    snackbarMsg:any='';

    constructor(private snackBar: MatSnackBar){}

    private subject = new Subject<Toasts>();
  errorSnackBar(msg) {
        this.snackbarMsg = msg;
        this.snackBar.openFromComponent(ToastsComponent, {
        //duration: this.durationInSeconds * 1000,
        panelClass: msg.type,
        horizontalPosition:  'center',
        verticalPosition: 'top',
        data: this.snackbarMsg
        });
    }

  //  AlertSnackBar(msg) {
  //    this.snackbarMsg = msg;
  //    this.snackBar.openFromComponent(ToastsComponent, {
  //    //duration: this.durationInSeconds * 1000,
  //    horizontalPosition:  'center',
  //    verticalPosition: 'top',
  //    });
  //}

    closeToast(){
      this.snackbarMsg={ msg: [""], type: 'error' };
        this.snackBar.dismiss();
    }
}
