import { Injectable } from '@angular/core';
import { AppInsights } from 'applicationinsights-js';
import { environment } from '../../../../environments/environment';
import { Router, ActivatedRoute, ResolveEnd, ActivatedRouteSnapshot } from '@angular/router';
import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey:environment.appInsights.instrumentationKey, //'INSTRUMENTATION_KEY',
  }
});
@Injectable({
  providedIn:'root'
})
export class AppInsightService {
  private config: Microsoft.ApplicationInsights.IConfig = {
    instrumentationKey: environment.appInsights.instrumentationKey
  };
 
  routerSubscription:Subscription;
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute) {
    if (!AppInsights.config) {
      AppInsights.downloadAndSetup(this.config);
      //roll name
            AppInsights.queue.push(() => {
                AppInsights.context.addTelemetryInitializer((envelope: Microsoft.ApplicationInsights.IEnvelope) => {
                    envelope.tags['ai.device.roleName'] = "INKASSO-UI";
                });
        
            });
            AppInsights.trackEvent('Login started'); 
    }
    this.routerSubscription = this.router.events
      .filter(event => event instanceof ResolveEnd)
      .subscribe((event: ResolveEnd) => {
          const activatedComponent = this.getActivatedComponent(event.state.root);
          if (activatedComponent) {
              this.logPageView(`${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`, event.urlAfterRedirects);
            }
      });
  }
  logPageView(
    name?: string,
    url?: string,
    properties?: any,
    measurements?: { [key: string]: number },
    duration?: number
  ) {
    AppInsights.trackPageView(name, url, this.AddGlobalProperties(properties), measurements, duration);
  }
  public logEvent(name: string, properties?: { [key: string]: string }, measurements?: { [key: string]: number }) {
    AppInsights.trackEvent(name, this.AddGlobalProperties(properties), measurements);
}
  logException(
    exception: Error,
    handledAt?: string,
    properties?: any,
    measurements?: any
  ) {
    
    AppInsights.trackException(exception, handledAt, properties, measurements);
  }
  public logError(error: Error, properties?: { [key: string]: string }, measurements?: { [key: string]: number }) {//(message: string, properties?: any, severityLevel?: any) {
    AppInsights.trackException(error, null, this.AddGlobalProperties(properties), measurements);
  }
  private AddGlobalProperties(properties?: { [key: string]: string }): { [key: string]: string } {
    if (!properties) {
        properties = {};
    }

    //add your custom properties such as app version

    return properties;
}
setAuthenticatedUserId(userId: string): void {
  AppInsights.setAuthenticatedUserContext(userId);
}
private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {

  if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
  }

  return snapshot.component;
}

private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
  let path = '';
  if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
  }

  if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
  }

  return path;
}
}
