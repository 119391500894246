import{Pipe,PipeTransform} from '@angular/core';

@Pipe({
  name:'currencyPipeFormat'
})
export class CurrencyPipe implements PipeTransform{
  transform(val:string):any{
    if(!val){
      return 'NA'
    }
   return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
}