import { Injectable } from "@angular/core";
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})

export class DateFormatService {


  constructor() {}

  createService() {

    let url = window.location.href;
    let urls = url.split('/');
    let countryCode = urls[3];
    let SE_FORMAT;
    let FI_FORMAT;
    if(countryCode == "se") {

        return SE_FORMAT = {
            parse: {
                dateInput: 'LL',
            },
            display: {
                dateInput: 'YYYY-MM-DD',
                monthYearLabel: 'MMM YYYY',
                dateA11yLabel: 'LL',
                monthYearA11yLabel: 'MMMM YYYY',
            },
        }

    }
    else if(countryCode == "fi") {

        return FI_FORMAT = {
            parse: {
                dateInput: 'LL',
            },
            display: {
                dateInput: 'DD.MM.YYYY',
                monthYearLabel: 'MMM YYYY',
                dateA11yLabel: 'LL',
                monthYearA11yLabel: 'MMMM YYYY',
            },
        }

    }
  
  }

}