import { Guid } from "./guid";
import { Subscription } from "rxjs";


/**
 * Represents an UploadQueue
 */
export class UploadQueue {
//   id: string;
  id:number;
  file: File;
  progress: number;
  message: string;
  isCancel: boolean;
  isError: boolean;
  sub?: Subscription;
  get isSuccess(): boolean {
    if (this.progress == 100)
      return true;

    return false;
  };

  constructor(file: File) {
    this.file = file;
    this.progress = 0;
    // this.id = Guid.newGuid();
    this.id= Math.random();
    this.message = '';
    this.isCancel = false;
    this.isError = false;
  }
}