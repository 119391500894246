import { Pipe, PipeTransform } from '@angular/core';
import { filter } from 'rxjs/operators';
import { InboxData } from 'src/app/shared/models/inboxData.model';

@Pipe({ name: 'assignToMePipe' })
export class AssignToMePipe implements PipeTransform {
  transform(value): InboxData {
    let assignData = value.filter(function(item) {
      return item.assignedToMe == true;
      });
    return assignData;
  }

}
