import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';

import { MatDatepickerModule } from "@angular/material/datepicker";





// ALL TIME REQUIRED 
import { AdminLayoutComponent } from './components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './components/layouts/auth-layout/auth-layout.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { TopBannerComponent } from './components/top-banner/top-banner.component';

// PIPES
import { CurrencyPipe } from 'src/app/views/pipe/currencyPipe'
// SERVICES

import { NavigationService } from "./services/navigation.service";
import { RoutePartsService } from './services/route-parts.service';
import { AuthGuard } from './services/auth/auth.guard';
import { ToastsComponent } from './components/toasts/toasts.component';
import { CdkTableModule } from '@angular/cdk/table';
import { DateFormatepipe } from 'src/app/views/pipe/date.pipe';
import { CurrencyFormatPipe } from 'src/app/views/pipe/currency.pipe';
//import { DndDirective } from './components/fileUploadModal/file-upload/dnd.directive';
import { ControlMessagesComponent } from './services/control-messages ';
import { TextMaskModule } from 'angular2-text-mask';
//import { MatSelectSearchModule } from 'app/views/financial-reports/mat-select-search/mat-select-search.module';
//import { MatSelectSearchComponent } from 'app/views/financial-reports/mat-select-search/mat-select-search.component';
import { CdkDetailRowDirective } from './directives/cdk-detail-row.directive';
import { ValidationService } from './services/validation-service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ShowSelectedServicesPipe, ShowSelectedReportNamePipe, ShowSelectedCatgoryReportNamePipe } from 'src/app/views/pipe/report';
import { InterestFormatPipe } from 'src/app/views/pipe/interestRate.pipe';

import { LoaderService } from './components/loader/service/loader.service';
import { ReplyMessage } from './components/reply-message/reply-message.component';
import { MsgFileUploadComponent } from './components/reply-message/file-upload/msg-file-upload.component';
import { DndDirective } from './components/reply-message/file-upload/dnd.directive';
import { AssignToMePipe } from 'src/app/views/pipe/assignToMe.pipe';

import { MessageTabUpdate } from 'src/app/views/debtor/overview.service';
import { MessageService } from 'src/app/views/inbox/message.service';
import { MsgUploadService } from './components/reply-message/file-upload/msg-upload.service';
import { ValueFormatepipe } from '../views/pipe/valueFormat';
import { NotificationPipe } from 'src/app/views/pipe/notification.pipe';
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';
import { DateFormatService } from './services/date-format-service';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatNativeDateModule, MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';



// export function HttpLoaderFactory(httpClient: HttpClient) {
//     return new TranslateHttpLoader(httpClient, "./assets/i18n/shared/", ".json");
// }

export function getBaseUrl() {
    return document.getElementsByTagName('base')[0].href.split('/')[3];
  }
  export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, "./assets/i18n/"+getBaseUrl()+"/shared/", ".json");
  }

/* 
  Only Required if you want to use Angular Landing
  (https://themeforest.net/item/angular-landing-material-design-angular-app-landing-page/21198258)
*/
// import { LandingPageService } from '../shared/services/landing-page.service';

const classesToInclude = [
    SidenavComponent,
    TopBannerComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    ReplyMessage,
    //GetValueByKeyPipe,
    MsgFileUploadComponent,
    DndDirective,
    AssignToMePipe,
    NotificationPipe
]

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule,
        MatSidenavModule,
        MatListModule,
        MatTooltipModule,
        MatOptionModule,
        MatSelectModule,
        MatMenuModule,
        MatSnackBarModule,
        MatGridListModule,
        MatToolbarModule,
        MatProgressBarModule,
        MatIconModule,
        MatButtonModule,
        MatRadioModule,
        MatCheckboxModule,
        MatCardModule,
        MatProgressSpinnerModule,
        MatRippleModule,
        MatDialogModule,
        CdkTableModule,
        MatDatepickerModule,
        MatInputModule,
        MatFormFieldModule,
        MatNativeDateModule,
        TextMaskModule,
        MatTableModule,
        DragDropModule,
        MatExpansionModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            isolate: true
        }),
    ],
    entryComponents: [],
    providers: [
        NavigationService,
        RoutePartsService,
        AuthGuard,
        ValidationService,
        MessageTabUpdate,
        MessageService,
        MsgUploadService,
        DateFormatService
    ],
    declarations: [
        classesToInclude,
        ToastsComponent,
        DateFormatepipe,
        CurrencyFormatPipe,
        InterestFormatPipe,
        ControlMessagesComponent,
        CdkDetailRowDirective,
        ShowSelectedServicesPipe,
        ShowSelectedReportNamePipe,
        ShowSelectedCatgoryReportNamePipe,
        CurrencyPipe, ValueFormatepipe
    ],
    exports: [classesToInclude, MatDatepickerModule, MatNativeDateModule,
        CdkTableModule, DateFormatepipe, CurrencyFormatPipe, InterestFormatPipe,
        ControlMessagesComponent, CdkDetailRowDirective, TextMaskModule,
        ShowSelectedServicesPipe, ShowSelectedReportNamePipe, ShowSelectedCatgoryReportNamePipe, CurrencyPipe, ValueFormatepipe
    ]
})
export class SharedModule {
    constructor(private translate: TranslateService, private cookieService: CookieService) {
        // let lang = this.cookieService.get('lang');
        // translate.setDefaultLang(lang);
    }
}
