import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

import { appConfig } from 'src/app/shared/services/config.service';
import { ToastsService } from 'src/app/shared/services/toast-service';
import { CustomHttpClient } from 'src/app/shared/services/http-client.service';
import { LoaderService } from 'src/app/shared/components/loader/service/loader.service';

export enum MessageReplyType {
    StartConversation,
    ConfirmNotification,
    ReplyUserQuestion,
    ReplyInConversation
}

export enum FixedMessageReplyType {
    FixedReply,
    ReplyWithNote,
    ReplyWithAttachment,
    ReplyAsConversation
}

export class MsgApiPayload {
    MessageReplyType: number;
    FixedMessageReplyType: number;
    MessageGuid: string;
    CaseIdentification: number;
    CaseIdentificationValue: string;
    ConversationSubject?: string;
    ReplyCode?: string;
    MessageText?: string;
    AttachedDocument?: object;
    ConversationGUID?: string;
    AssignToMe: boolean
}

export class NotificationPayload {
    MessageReplyType :number;
    FixedMessageReplyType :number;
    ButtonType:string;
    notificationMessage: NotificationMessagePayload[];
}

export class NotificationMessagePayload {
    MessageGuid : string;
    AssignToMe : boolean;
    }

@Injectable()
export class MessageService {
    private setColumn = new Subject<any>();
    public updateTableData = new Subject<any>();
    public selectAllCheckBox = new Subject();
    public updateInboxData = new Subject<any>();
    API_URL: any = '';
    constructor(
         private _https: CustomHttpClient,
         private _global: appConfig,
          private toastservice: ToastsService,
          private loaderService: LoaderService) {
        //this.API_URL = this._global.baseAppUrl + 'InkassoSession/';
        this.API_URL = this._global.baseAppUrl + 'Inbox/';

    }
  // refreshing inbox table
    setRefreshTable(data:any) {
        this.updateTableData.next(data);
    }
    getRefreshTable() {
        return this.updateTableData.asObservable();
    }

    setInboxTable(data:any) {
        this.updateInboxData.next(data);
    }
    getInboxTable() {
        return this.updateInboxData.asObservable();
    }

    setNotificationCheckValue(data) {
        this.selectAllCheckBox.next(data);
    }
    getNotificationCheckValue() {
        return this.selectAllCheckBox.asObservable();

    }

    public GetInboxMessagesForClient(): Observable<any> {
        return this._https
            .get(this.API_URL + 'GetInboxMessagesForClient')
            .map(response => {
                const inboxTabData = response;
                return inboxTabData;
            })
            
    }

    // Getting data for ToDo Tab in Inbox
    public GetToDoMessage(): Observable<any> {
        return this._https
            .get(this.API_URL + 'GetToDo')
            .map(response => {
                const toDOTabData = response;
                return toDOTabData;
            })
    }

    // Getting data for Notification Tab in Inbox
    public GetNotificationMessage() {
        return this._https
            .noLoaderHttp(this.API_URL + 'GetNotification')
            .map(response => {
                const notificationData = response;
                return notificationData;
            })
    }

    // Getting data for AssignToMe Tab in Inbox
    public GetAsignToMeMessage() {
        return this._https
            .noLoaderHttp(this.API_URL + 'GetAsignToMe')
            .map(response => {
                const assignedToMeData = response;
                return assignedToMeData;
            })
    }

    // Getting data for History Tab in Inbox
    public GetHistoryMessage() {
        return this._https
            .noLoaderHttp(this.API_URL + 'GetHistory')
            .map(response => {
                const historyData = response;
                return historyData;
            })
    }

    
  // AssignToMe For A Message
    public AssignToMe(messageGuid, assigned): Observable<any> {
         return this._https
            .get(this.API_URL + 'GetAssignToMe/' + assigned + '/' + messageGuid )
            .map(response => {
                const assignedData = response;
                return assignedData;

            })
            
     }


    //Inbox Action Service 
    public notificationAction(expandData): Observable<any> {
        const formData = new FormData();
        let messagePayload:any = {​​​​
            "MessgaeType": "Notify",
            "MessageGuid": (expandData.messageGuid != undefined) ? expandData.messageGuid : expandData.MessageGuid
        }
        
        formData.append('messagePayload', JSON.stringify(messagePayload));​​​​
        console.log(messagePayload);

        return this._https
          .post(this.API_URL + 'PostMessage', formData)
            .map(response => {
                const msgResponse = response;
                return msgResponse;
            })
            
    }


    //This service is used only for Message modal to post messages 
    public sendMessageResponses(replyModalDetail, uploadDataVal, event): Observable<any> {
        const formData = new FormData();
        console.log(replyModalDetail);

        let messagePayload:any={​​​​
            "CaseGUID":replyModalDetail.CaseGUID,
            "Message":replyModalDetail.RespondedText,
            "MessgaeType":replyModalDetail.MessageType
        }​​​​

        if(replyModalDetail.MessageType == "Reply"){
            messagePayload.MessageGuid = replyModalDetail.MessageGuid,
            messagePayload.ConversationGUID = replyModalDetail.conversationGuid,
            messagePayload.AssignToMe = replyModalDetail.AssignToMe
        }
        if(replyModalDetail.MessageType == "FreeTextFixedReply"){
            messagePayload.MessageGuid = replyModalDetail.MessageGuid,
            messagePayload.Subject = replyModalDetail.Subject,
            messagePayload.AssignToMe = replyModalDetail.AssignToMe,
            messagePayload.ReplyCode = replyModalDetail.ReplyCode
        }

        if (uploadDataVal != null && uploadDataVal.documentLookupModel != null) {
            formData.append('file', uploadDataVal.documentLookupModel.file);
            // formData.append('RequestPayload', JSON.stringify(messagePayload))
        }
        formData.append('messagePayload', JSON.stringify(messagePayload));
        console.log(messagePayload);

        return this._https
            .post(this.API_URL + 'PostMessage', formData)
            .map(response => {
                const msgResponse = response;
                return msgResponse;
            })
            
    }

    //For getting Message tab Data 
    public messageCaseData(Guid): Observable<any> {
        // let RequestPayload =
        // {
        //     "CaseIdentification": 1,
        //     "CaseIdentificationValue": Guid
        // };
        return this._https
            .get(this.API_URL + 'GetMessagesForCase/'+Guid)
            .map(response => {
                const msgTabData = response;
                return msgTabData;
            })
    }

    // // For Withdraw Case
    // public revokeCase(caseNumber): Observable<any> {
    //     let RequestPayload =
    //     {
    //         "CaseLookupRequest": {
    //             "CaseIdentification": 0,
    //             "CaseIdentificationValue": caseNumber
    //         }
    //     };
    //     return this._https
    //         .post(this.API_URL + 'RevokeCaseofClient', RequestPayload)
    //         .map(response => {
    //             const WithdrawResponse = response;
    //             return WithdrawResponse;
    //         })
            
    // }


    public inboxAction(event, expandData,caseGuid, replyBox, uploadDataVal): Observable<any> {
   
        let assignToMe = (expandData.assignedToMe == true);
        const formData = new FormData();

        let messagePayload:any = {​​​​
            "CaseGUID": caseGuid,
            "ReplyCode": event.target.value,
            "AssignToMe": assignToMe,
            "MessgaeType": "FixedReply",
            "MessageGuid": expandData.messageGuid
            }​​​​
        if (replyBox) {
            messagePayload.Message = replyBox;
        }
        if (uploadDataVal != null && uploadDataVal.documentLookupModel != null) {
            formData.append('file', uploadDataVal.documentLookupModel.file);
            // formData.append('RequestPayload', JSON.stringify(messagePayload))
        }
        console.log(messagePayload);
        formData.append('messagePayload', JSON.stringify(messagePayload))

        return this._https
          .post(this.API_URL + 'PostMessage', formData)
            .map(response => {
                const msgResponse = response;
                return msgResponse;
            })
            
    }

    // For Notification Ok Checked
    public multipleNotificationChecked(checkedNoticeDetails,btnType): Observable<any> {
        // let assignToMe = (expandData.assignedToMe == true);
        let messageDetails:NotificationMessagePayload[] = checkedNoticeDetails;
        let RequestPayload:NotificationPayload =
        {
            MessageReplyType :1,
            FixedMessageReplyType : 1,
            ButtonType:btnType,
            notificationMessage: messageDetails

      };
      const formData = new FormData();

      formData.append('file', "");
      formData.append('notificationRequest', JSON.stringify(RequestPayload))
        return this._https
          .post(this.API_URL + 'ConfirmNotificationMessages', formData) 
            .map(response => {
                const noticeResponse = response;
                return noticeResponse;
            })
            
    }

}
