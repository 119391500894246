import { Component, OnInit, Input } from '@angular/core';

import { Toasts } from 'src/app/shared/models/toasts';
import { ToastsService } from '../../services/toast-service';
@Component({
  selector: 'app-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.scss'],
  // providers:[ToastsComponent]
})
export class ToastsComponent implements OnInit {
  @Input() id: string;
  toasts: Toasts[] = [];
  errorMessage: string = "";
  typeMsg: any = '';
  constructor(private toastsService: ToastsService) { }

  ngOnInit() {
      this.typeMsg = this.toastsService.snackbarMsg;
  }
  closeToast() {
    this.typeMsg = '';
    this.toastsService.closeToast();
  }

}
