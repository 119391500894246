

  <div *ngIf="typeMsg.type=='error' && typeMsg.msg!=''" class="error">
    <i class="icofont-exclamation-tringle tringle-align"></i>
    <div *ngFor="let Msg of typeMsg.msg" class="error_msg">

      {{typeMsg.msg}}
    </div>
    
    <i class="icofont-close-line close-icon-design" (click)="closeToast()"></i>
   

  </div>
<div *ngIf="typeMsg.type=='success' && typeMsg.msg!=''" class="success">
  <span>
    <i class="icofont-check-circled check-align"></i>
    {{typeMsg.msg}}
    <i class="icofont-close-line close-icon-design" (click)="closeToast()"></i>
  </span>
</div>
