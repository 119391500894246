
import { Inject, Injectable } from "@angular/core";
import * as moment from 'moment';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { FormControl } from "@angular/forms";
import { APP_BASE_HREF } from "@angular/common";
@Injectable({
    providedIn: 'root'
})


export class ValidationService {
    public static organizationRadio: number = 1;
    public static validFile: any = '';
    countryCode: any = "";

    constructor(private http: HttpClient, @Inject(APP_BASE_HREF) private baseHref: string) {

    }
    init() {
        let url = window.location.href;
        let urls = url.split('/');
        this.countryCode = urls[3];
        // this.http.get('assets/validation.json').subscribe(res => {
        //     ValidationService.validFile = res;//.json();
        //   });
        this.fetchViaHttp().subscribe(res => {
            ValidationService.validFile = res;//.json();
        });
        console.log(ValidationService.validFile);

    }
    fetchViaHttp(): Observable<any> {
        return this.http.get('assets/Validation/' + this.baseHref.split('/')[1] + '/validation.json');
        // return this.http.get('assets/validation.json');
    }
    public static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
        let config = ValidationService.validFile.config;

        return config[validatorName];
    }

    public static noWhitespace(control: FormControl) {
        let isWhitespace = (control.value || '').trim().length === 0;
        let isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true }
    }


    static pincodeValidator(control) {
        if (control.value) {
            var regEx = new RegExp(ValidationService.validFile.pincode.regularExp);
            if (control.value.replace(/\s/g, '').match(regEx)) {
                return null;
            }
            else {
                return { 'invalidPostalCode': true };
            }
        }

    }
    static emailValidator(control) {
        if (control.value) {
            var regEx = new RegExp(ValidationService.validFile.emailValidator.regularExp);
            if (control.value.match(regEx)) {
                return null;
            } else {
                return { 'invalidEmailAddress': true };
            }
        }

    }
    static organisationValidator(control) {
        if (control.value) {
            var regEx = new RegExp(ValidationService.validFile.orgNumber.regularExp);
            // var value = control.value.replace(/-/g, '')
            if (control.value.match(regEx)) {
                return null;
            }
            //  else if (value.length == 8) {
            //     return null;
            // }
            else {
                return { 'invalidOrganisationNumber': true };
            }
        }
    }
    static ssnValidator(control) {
        let url = window.location.href;
        let urls = url.split('/');
        let countryCode = urls[3];
        // console.log(control.value);
        if (control.value) {
            var regExp = new RegExp(ValidationService.validFile.ssnNumber.regularExp);
            if (countryCode == 'se') {
                let age = control.value.substring(0, 4) + "-" + control.value.substring(4, 6) + "-" + control.value.substring(6, 8);
                let dob = 0;
                if (new Date(age)) {
                    dob = moment().diff(new Date(age), 'years')
                }

                if (regExp.test(control.value)) {
                    if (dob >= 18) {
                        return null;
                    }
                    else {
                        return { 'invalidAge': true };
                    }
                } else {
                    return { 'invalidSsnNumber': true };
                }
            } else {
                let age;
                let ssnDate = control.value.substring(0,6);
                let currentDate = new Date();
                let currentYear = String(currentDate.getFullYear()).substring(0,2);
                if(Number(ssnDate.substring(4,6)) > Number(currentYear)){
                    age = 19 + ssnDate.substring(4,6) + "-" + ssnDate.substring(2,4) + "-" + ssnDate.substring(0,2);
                }else{
                    age = 20 + ssnDate.substring(4,6) + "-" + ssnDate.substring(2,4) + "-" + ssnDate.substring(0,2);
                }
                
                let dob = 0;
                if (new Date(age)) {
                    dob = moment().diff(new Date(age), 'years')
                }
                if (regExp.test(control.value)) {
                    if (dob >= 18) {
                        return null;
                    }
                    else {
                        return { 'invalidAge': true };
                    }
                } else {
                    return { 'invalidSsnNumber': true };
                }
            }


            // }
            // else {

            //     var regEx = new RegExp(ValidationService.validFile.orgNumber.regularExp);
            //     if (regEx.test(control.value.replace(/-/g, ''))) {
            //         return null;
            //     }else{
            //         return { 'invalidOrganizationNumber': true };
            //     }
            // }

        }

    }


    // Full name validation
    static fullNameValidator(control) {
        if (control.value) {
            let val = ValidationService.validFile.personName.regularExp;
            var regExp = new RegExp(val);
            if (control.value.match(regExp)) {
                return null;
            } else {
                return { 'invalidName': true };
            }
        }

    }

    static orgFullNameValidator(control) {
        if (control.value) {
            let val = ValidationService.validFile.orgName.regularExp;
            var regExp = new RegExp(val);
            if (control.value.match(regExp)) {
                return null;
            } else {
                return { 'invalidName': true };
            }
        }

    }

    // Full name validation
    static referenceValidator(control) {
        if (control.value) {
            var regExp = new RegExp(ValidationService.validFile.referencevalidator.regularExp);
            if (control.value.match(regExp)) {
                return null;
            } else {
                return { 'invalidReference': true };
            }
        }

    }

    //Address validation
    static AddressValidator(control) {
        if (control.value) {
            var regExp = new RegExp(ValidationService.validFile.addressvalidator.regularExp);
            if (control.value.match(regExp)) {
                return null;
            } else {
                return { 'invalidAddress': true };
            }
        }

    }

    // City validation
    static CityValidator(control) {
        if (control.value) {
            var regExp = new RegExp(ValidationService.validFile.cityvalidator.regularExp);
            if (control.value.match(regExp)) {
                return null;
            } else {
                return { 'invalidCity': true };
            }
        }

    }

    //Phone Validation
    static validPhone(control) {
        if (control.value) {
            var regExp = new RegExp(ValidationService.validFile.validPhone.regularExp);
            if (control.value.match(regExp)) {
                return null;
            } else {
                return { 'invalidPhoneNumber': true };
            }
        }

    }

    public static setTypeOrganisation(val) {
        ValidationService.organizationRadio = val;
    }

    //For Numbers Only
    static numbersOnly(control) {
        if (control.value) {
            var regExp = new RegExp(ValidationService.validFile.numbersonly.regularExp);
            if (control.value.match(regExp)) {
                return null;
            } else {
                return { 'invalidNumber': true };
            }
        }

    }

    //For Amount 
    static validAmount(control) {
        if (control.value) {
            var regExp = new RegExp(ValidationService.validFile.validAmount.regularExp);
            if (control.value.match(regExp)) {
                return null;
            } else {
                return { 'invalidAmount': true };
            }
        }

    }
    //For Invoice Number only
    static invoiceNumberValidation(control) {
        if (control.value) {
            var regExp = new RegExp(ValidationService.validFile.invoiceNumber.regularExp)
            if (control.value.match(regExp)) {
                return null;
            } else {
                return { 'invalidInvoice': true };
            }
        }
    }
    //For Amount Validation
    static amountFormat(control) {
        if (control.value) {
            var regExp = new RegExp(ValidationService.validFile.numbersonly.regularExp);
            if (control.value.match(regExp)) {
                return null;
            } else {
                return { 'invalidAmount': true };
            }
        }

    }
}

