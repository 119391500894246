import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
@Injectable({
    providedIn: 'root'
  })
export class LoaderService {
    private _loading: boolean = false;
    loadingStatus: Subject<any> = new Subject();
    _spinnerActionOccured: Subject<void> = new Subject();

    get spinnerActionOccured(): Observable<void> {
      return this._spinnerActionOccured.asObservable();
    }
  
    notifySpinnerAction(active) {
      this._spinnerActionOccured.next(active);
    }
  
    startLoading() {
      this.notifySpinnerAction(true);
    }
  
    stopLoading() {
      this.notifySpinnerAction(false);
    }
}