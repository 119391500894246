import { Injectable } from "@angular/core";


@Injectable({
  providedIn: 'root'
})

export class CountryCodeService {
  countryCode: string;
  constructor() {}

  getCountryCode(){
    let url = window.location.href;
    let urls = url.split('/');
    this.countryCode = urls[3];
    return this.countryCode;
  }
}

