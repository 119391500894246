import { Pipe, PipeTransform } from '@angular/core';
import { InboxData } from 'src/app/shared/models/inboxData.model';

@Pipe({ name: 'notificationPipe' })
export class NotificationPipe implements PipeTransform {
  transform(value): InboxData {
    let notificationData = value.filter(function(item) {
      return item.messageTypecode == 1;
      });
    return notificationData;
  }

}
