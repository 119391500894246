import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import{Observable} from "rxjs/Observable";
import { HttpClient } from "@angular/common/http";
import { appConfig } from "../../shared/services/config.service";

@Injectable({
    providedIn: 'root'
  })
export class LanguageService{
    private subject = new Subject<any>();
    API_URL: any = '';
    constructor(private httpClient: HttpClient, private _global: appConfig) {
        this.API_URL = this._global.baseAppUrl + 'Utilities';
    }

    setLanguage(lng:string)
    {
        this.subject.next({"language": lng })
    }
    getLanguage(): Observable<any> {
        return this.subject.asObservable();
    }

    setLanguageCookies(lng: string) {
        return this.httpClient.get(this.API_URL + '/SetCookies?culture=' + lng).map(res => {
            return res;
        })
    }
}
