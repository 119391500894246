import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { AuthorizationService } from './authorization.service';
import { AuthGroup } from './authorization.types';
import {Observable } from 'rxjs/Observable';

@Injectable()
// export class AuthGuard implements CanActivate {
//   public authToken;
//   private isAuthenticated = true; // Set this value dynamically

//   constructor(private router: Router) {}
//   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
//     if (this.isAuthenticated) {
//       return true
//     }
//     this.router.navigate(['/sessions/signin']);
//     return false;
//   }
// }

export class AuthGuard implements CanActivate {
  public authToken;
  private isAuthenticated = true; // Set this value dynamically
  public invoked = false;
  public arr = ["home","help"];
  public userDetails:any;

  constructor(private router: Router, private authorizationService: AuthorizationService) { 
   console.log("constructorAuth");
   this.userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
  }


  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log("canActive");
    if(this.userDetails){
      if (!this.hasPermision(this.arr, route.children[0].data['auth'])) {
        this.router.navigate(['/404']);
        return false;
      } else {
        return true;
      }
    }else{
      return true;
    }
    
    // if(!this.invoked){
    //   this.invoked = true;
    //   return this.authorizationService.userLoginDetails().map((data:any) => {
    //     if (data) {
    //       let userDetails = data;
    //       sessionStorage.setItem("userDetails", JSON.stringify(userDetails));
    //       this.authorizationService.setuserLoggedSubject(data);
    //       var arr=[];
    //       data.allowedComponentsList.map((item:any)=>{
    //         arr.push(item.appCompKey);
    //       });
    //         arr.push('home');
    //         arr.push('help');
    //         if (!this.hasPermision(arr, route.children[0].data['auth'])) {
    //         this.router.navigate(['/404']);
    //         return false;
    //       }
    //       return this.hasPermision(arr,route.children[0].data['auth'])
    //     }
    // }).catch(() => {
    //   this.router.navigate(['/404']);
    //     return Observable.of(false);
    // });
    // }else{
    //   this.router.navigate(['/404']);
    //   return false;
    // }
    
  }

  
  hasPermision(arr,authGroup)
  {
    if (arr && arr.find(permission => {
        return permission === authGroup;
      })) {
        return true;
      }
      //  sessions/404
      return false;
  }
  protected hasRequiredPermission(authGroup: AuthGroup) {
   // 
    //let userDetails = this.authorizationService.userObj//this.authorizationService.userLoginDetails();
    // this.authorizationService.userLoginDetails().subscribe((userDetails) => {
    //   //console.log("userDetails:: " + userDetails.json());
    //   this.authorizationService.setuserLoggedSubject(userDetails);
    // });

    

    // If user’s permissions already retrieved from the API
    //console.log();
   let activeRoute=false;
  
  // this.authorizationService.userLoginDetails().subscribe((data:any)=>{
  //     var arr=[];
  //     data.allowedComponentsList.map((item:any)=>{
  //       arr.push(item.appCompKey);
  //     })
  //  activeRoute=this.hasPermision(arr,authGroup)
      
  //   })
  //   console.log(activeRoute);
  //   return activeRoute;
  //   if (this.authorizationService.userLoginDetails()) {
  //     var arr=[];
  //     var activeRoute=true;
  //     this.authorizationService.userLoginDetails().subscribe((data:any)=>{
       
  //       data.allowedComponentsList.map((item:any)=>{
  //         arr.push(item.appCompKey);
  //       });
  //       activeRoute=this.authorizationService.hasPermission(authGroup,arr);
  //     })
  //     console.log("this.authorizationService.permissions");
  //     // if (this.authorizationService.hasPermission(authGroup)) {

  //     //   // return this.authorizationService.hasPermission(authGroup);
  //     //   return true;
  //     // }
    
  //     // if (authGroup) {
  //     //   console.log("authGroup");
  //     //     return this.authorizationService.hasPermission(authGroup,arr);
  //     // } 
  //     // else {
  //     //   //return true;
  //     //   this.router.navigate(['sessions/404']);

  //     //   return this.authorizationService.hasPermission(null,arr);
  //     // }
  //    return activeRoute;
  //   } 
  //   else {
  //     // Otherwise, must request permissions from the API first
  //     // const promise = new Promise<boolean>((resolve, reject) => {
  //     //     this.authorizationService.initializePermissions()
  //     //         .then(() => {
  //     //             if (authGroup) {
  //     //                 resolve(this.authorizationService.hasPermission(authGroup));
  //     //            } else {
  //     //                 resolve(this.authorizationService.hasPermission(null));
  //     //             }
  //     //         }).catch(() => {
  //     //             resolve(false);
  //     //         });
  //     // });
  //     // return promise;
  //   }
  // }
}

}
// export class AuthGuard implements CanActivate {

//   // Inject Router so we can hand off the user to the Login Page 
//   constructor(private router: Router) {}

//   canActivate(
//     next: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

//        if ( sessionStorage.getItem('x-auth') ){
//          // Token from the LogIn is avaiable, so the user can pass to the route
//          return true
//        } else  {
//          // Token from the LogIn is not avaible because something went wrong or the user wants to go over the url to the site
//          // Hands the user to the LogIn page 
//          alert("You are currently not logged in, please provide Login!")
//          this.router.navigate( ["/login"] );
//          return false

//        }

//   }
// }
