import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'currencyPipe' })
export class CurrencyFormatPipe implements PipeTransform {

  transform(value: any): any {
    let url = window.location.href;
    let urls = url.split('/');
    let countryCode = urls[3];
    let currencySymbol: string = '';

    if (countryCode == 'fi'){
      currencySymbol = "€"
    }
    else if(countryCode == 'se'){
       currencySymbol = "SEK"
    }

    let currencyArray = value == undefined ? "0" : value.toString();
    let decimalPos = currencyArray.indexOf(".");
    let commaPos = currencyArray.indexOf(",");
    if(currencyArray.indexOf(".") != -1){ 
      let format  = currencyArray.replace(".", ",");
      let pos = format.indexOf(",");
        let len = format.length;
        if(len - pos < 3){
          format = format + "0 "+ currencySymbol;
          return format;
        }
        format = format + " " + currencySymbol;
        return format;
    }else if(currencyArray.indexOf(",") != -1){
      let format  = currencyArray;
      let pos = format.indexOf(",");
        let len = format.length;
        if(len - pos < 3){
          format = format + "0 "+ currencySymbol;
          return format;
        }
        format = format + " " + currencySymbol
        return format;
    }
    else{
        let format  = currencyArray;
        format = format + ",00 "+ currencySymbol
        return format;
    }
     
  }

}
