<div class="wrapper custom-wrapper">
  <!-- <div class="modal_header u_header">
     <h2 mat-dialog-title class="title_left u_title" style="text-align: left;">UPLOAD INVOICE</h2>
     <button class="close-upload" mat-dialog-close aria-hidden="true">
     <i class="icofont-close-line " mat-dialog-close aria-hidden="true"></i>
     </button>
  </div> -->
  <mat-dialog-content class="mat-typography">
     <div class="area"  appDnd (filesChangeEmiter)="onFilesChange($event)" (filesInvalidEmiter)="onFileInvalids($event)" [allowed_extensions]="accepts">
     <div class="dropzone">
        <div class="progress" *ngIf="uploadBtn==true;else uploadBtns ">
           <!-- <div class="progressPerscent">{{progress.loaded+'%'}}</div>-->
           <div class="progtext">{{progressText}}</div> 
           <mat-progress-bar class="progress_bar" mode="{{mode}}" [value]="progress.loaded"></mat-progress-bar>
        </div>
        <ng-template #uploadBtns>
           <div class="file_upload">
              <img class="drag-img" src="assets/images/file_upload.svg"/>
              <img class="drop-img" src="assets/images/file_upload_gray.svg"/>
           </div>
           <h1 class="drop-file">{{'dropFilesUploadProcess' | translate}}</h1>
           <h1 class="drag-file"> {{'dragFilesFromComputer' | translate}} <span class="browse" (click)="onSelectChange($event)">browse</span></h1>
           <div class="pdfpng-5"> {{'pdfOrJpgOnly' | translate}} </div>
        </ng-template>
     </div>
</div>
<!-- </div> -->
<div>
   <!--  -->
   <mat-list *ngIf="uploadBtn==false">
         <mat-list-item *ngFor="let item of uploader.queue; let last = last"> 
               <p mat-line>{{item.file.name}}</p>
               <mat-icon (click)="cancelFile(item)" style="cursor: pointer;"><i class="icofont-bin"></i></mat-icon>
               <mat-divider *ngIf="!last"></mat-divider>
            </mat-list-item>
   </mat-list>
<!-- <ul *ngIf="uploadBtn==false">
<li class="file_item" *ngFor="let item of uploader.queue">
<i class="icofont-close-circled" style="clear:both;float:none;padding-right:5px;font-size: 17px;" (click)="cancelFile(item)"></i>  
{{item.file.name}}
</li>
</ul> -->
<mat-list *ngIf="fileNameQueueLength>0 && uploadBtn==true">
      <mat-list-item *ngFor="let item of fileNameQueue; let last = last"> 
            <p mat-line>{{item.fileName}}</p>
             <mat-icon ><i class="icofont-checked" *ngIf="item.type=='suceess'"></i><i class="icofont-close-squared" *ngIf="item.type=='error'"></i></mat-icon>
             <mat-divider *ngIf="!last"></mat-divider>
            </mat-list-item>
</mat-list>
<!-- <ul *ngIf="fileNameQueueLength>0 && uploadBtn==true">
   <li class="file_item" *ngFor="let item of fileNameQueue">
        
   </li>
</ul> -->
</div>
<input type="file" id="file" uploader="uploader" #myInput   name="fileUpload" multiple="multiple"  style="display:none;" />
</mat-dialog-content>
<!-- <mat-dialog-actions align="end" class="mat-footer" style="margin-top: 10px;">
  <div class="sergel-one">
  </div>
  <div class="sergel-two">
     <div class="outline-btn_border" [ngClass]="{'success_border':uploadBtn==true}">
        <button class="succes_btn btn-dark"  mat-button  cdkFocusInitial (click)="uploadData()" [ngClass]="{'btn-disabled':uploadBtn==true || uploadQuelength==0}">SEND</button>
     </div>
  </div>
</mat-dialog-actions> -->
</div>