import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Pipe({ name: 'datePipe' })

export class DateFormatepipe extends DatePipe implements PipeTransform {
  // adding a default format in case you don't want to pass the format
  // then 'yyyy-MM-dd' will be used
  transform(value: any, args?: any): any {
    if (!value) {
      return 'NA'
    }

    let url = window.location.href;
    let urls = url.split('/');
    let countryCode = urls[3];

      let year :any;
      let month :any;
      let day :any;

      let newDate = new Date(value);
      let d = newDate.getTime();
      year = newDate.getFullYear();
      month = newDate.getMonth() + 1;
      day = newDate.getDate();
      if(month < 10){
        month = "0"+month;
      }
      if(day < 10){
        day = "0"+day;
    }
    //console.log(value);
    
    if (countryCode == 'se')
    {
      return [year, month, day].join("-");
    }
    else if( countryCode == 'fi')
    {
      return [day, month, year].join(".");
    }

 
     
  }

}

