export class customEnums {

}

export enum Currency {
  Sweden = "SEK"
}

export enum fileSizeEnum {
  CaseUploadFileSizeInMB = 5
}
