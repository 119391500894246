import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'intRatePipe' })
export class InterestFormatPipe implements PipeTransform {
    transform(value: any): any {
        let intRateArray = value.toString();
        if (intRateArray.indexOf(",") == -1 && intRateArray.indexOf(".") == -1) {
            let format = intRateArray + ",00 %";
            return format;
        } else if (intRateArray.indexOf(".") != -1) {
            let format = intRateArray;
            format = format.replace(".", ",") + " %";
            return format;
        } else {
            let format = intRateArray;
            format = format + " %"
            return format;
        }

    }

}
