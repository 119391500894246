import { Component, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { LoaderService } from './service/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  loading: boolean = false;
  loadingSubscription: Subscription;

  constructor(private loadingScreenService: LoaderService) {
    this.loadingScreenService.spinnerActionOccured.subscribe((res: any) => {
            this.loading = res;
          });
  }

  ngOnInit() {
    // this.loadingSubscription = this.loadingScreenService.loadingStatus.subscribe((value) => {
    //   this.loading = value;
    // });
  }

  ngOnDestroy() {
    // this.loadingSubscription.unsubscribe();
  }

}
