import { PipeTransform, Pipe } from "@angular/core";


@Pipe({
    name: 'showSelectedServices',
    pure: false
  })
  export class ShowSelectedServicesPipe implements PipeTransform {
    transform(items: any[], filter: Object): any {
        if (!items || !filter) {
            return items;
        }
        return items.filter(item => item.isSelect == true);
    }
  }
  @Pipe({
    name: 'showSelectedReportName',
    pure: false
  })
  export class ShowSelectedReportNamePipe implements PipeTransform{
    transform(items: any[], filter: Object): any {
        if (!items || !filter) {
            return items;
        }
        return items.filter(item => item.isSelect == true);
    }
  }
  @Pipe({
    name: 'showSelectedCategoryByReportName',
    pure: false
  })
  export class ShowSelectedCatgoryReportNamePipe implements PipeTransform{
    transform(items: any[], filter: Object): any {
        console.log(items);
       
        if (!items || !filter) {
            return items;
        }
        return items //items.filter(item => item.isSelect == true);
    }
  }
