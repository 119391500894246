import { Injectable } from "@angular/core";
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/Rx';

import { Observable, Subject, throwError } from 'rxjs';

import { HttpHeaders } from '@angular/common/http';
import { appConfig } from '../../shared/services/config.service';
import { CustomHttpClient } from "src/app/shared/services/http-client.service";

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})

export class SearchDataService {
  public API_URL: string;

  constructor( public urlConfigs: appConfig, private http: CustomHttpClient) {
    this.API_URL = this.urlConfigs.baseAppUrl + "Search/";
  }


  // New method to getData from Search API
  getSearchData(searchData:any){
    return this.http
      .post(this.API_URL + 'PostCaseSearch/', searchData)
    .map((response:any) => {        
        return response;
    })
  }

}

@Injectable()
export class SearchService {
  private subject = new Subject<any>();
  private rowsPerColumn = new Subject<any>();

  getColumns() {
    return this.subject.asObservable();
  }

  clearColumns() {
    this.subject.next();
  }

  setColumns(columns: any) {
    this.subject.next(columns);
  }
  setRowsPerCol() {

    return this.rowsPerColumn.asObservable();
  }
  getRowsPerCol(colNumbers: any) {
    this.rowsPerColumn.next(colNumbers)
  }
}
