import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'valueEmpty' })
export class ValueFormatepipe implements PipeTransform {
  // adding a default format in case you don't want to pass the format
  // then 'yyyy-MM-dd' will be used
  transform(value: any, args?: any): any {
    if(value===undefined || value===null || value===0|| value==='')
    {
     
      return 'NA'; //super.transform("NA",Constants.DATE_FMT);
    }
    else{
      return value;
    }
   
  }

}
